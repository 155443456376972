<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="auto">
        <v-alert outlined type="info">{{ $t("m.underConstruction") }}</v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_UNDER_CONSTRUCTION)
  }
}
</script>
